export const FEATURE_FLAG = {
  DASHBOARD: 'showDashboardNavLink',
  ITINERARY_BUILDER_2: 'useItineraryBuilder2',
  EDIT_ITINERARY_2: 'useEditItinerary2',
  CC_BOOKING_OWNER: 'ccBookingOwner',
  REST_OF_WORLD: 'showRestOfWorld',
  VIDEO_ASSEMBLER_2: 'useVideoAssembler2',
  PAYMENT_RECEIPT_PREFER_LIGHT_COLORS: 'paymentReceiptPreferLightColors',
  MARGIN_PROTECTION: 'marginProtection', // isMarginProtectionEnabled
  // email specific
  EMAILS_PAYMENT_CSV_ATTACH: 'paymentCsvAttach',
  EMAILS_SEND_INVOICE_UPDATES: 'emailSendInvoiceUpdates',
};
